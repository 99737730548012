<script>
import router from "../../../../router";
import {mapGetters} from "vuex";
import moment from 'moment-timezone'
export default {
  name: "SuccesCreateInput",
  data(){
    return {
      timeBeforeTest: 120
    }
  },
  methods:{
    closeModal(){
      this.$emit('closeModal')
      this.$emit('reloadPage')
    },
    showDetail(){
      router.push({ name: 'customer-test-input-schedule', params: {id: this.idCustomer}})
    },
    formatDay(day,isEnd = false){
      let momentObj = moment(day, "YYYY-MM-DD HH:mm:ss")
      if(isEnd){
        momentObj.subtract(1, 'hours');
      }else {
        momentObj.subtract(2, 'hours');
      }

      return  momentObj.format("HH:mm:ss DD-MM-YYYY");
    }
  },
  computed: {
    ...mapGetters(['isPilot']),
    testHaveScheduleBefore1Hour(){
      const timeConfirmTest = moment().valueOf() + this.timeBeforeTest * 60 * 1000
      const testSchedule = moment(this.successCreateData?.schedule).valueOf()
      return timeConfirmTest < testSchedule
    }
  },
  props:{
    idCustomer:{
      type:Number,
      default(){
        return null
      }
    },
    showDetailButton : { 
      type: Boolean,
      default: true
    },
    successCreateData:{
      type: Object,
      default() {
        return null;
      }
    }
  }
}
</script>

<template>
  <div class="text-center">
    <img src="../../../../../public/media/succesTestInput.png" alt="">
    <h1 style="font-size: 16px;color: #40C057;
" class="mt-2" >Thành công</h1>
    <h1 class="mt-3" style="font-size: 13px;
" v-if="!isPilot || !testHaveScheduleBefore1Hour">Vui lòng chọn Xem chi tiết để xem thông tin ca test.</h1>
    <div style="text-align: center" v-else>
      <p class="bold">Chúc mừng bạn đã đặt lịch test speaking thành công!</p>
      <p class="bold">Bạn cần xác nhận khách hàng tham gia test speaking trước thời gian bắt đầu 02 tiếng.</p>
      <p style="color: #5E00B6;" class="bold">Thời gian xác nhận: Từ {{formatDay(successCreateData?.schedule)}} đến {{formatDay(successCreateData?.schedule,true)}}</p>
      <p class="bold">Tới thời gian xác nhận, vui lòng truy cập website nội bộ, vào chi tiết ca test để tiến hành xác nhận.</p>
      <p style="color: red" class="bold">Trường hợp không xác nhận, lịch test speaking sẽ bị hủy.</p>
    </div>
    <button type="button" class="btn btn-secondary mt-3 mr-3" @click="closeModal()">Đóng</button>
    <button v-if="showDetailButton" type="button" class="btn btn-primary mt-3" @click="showDetail()">Xem chi tiết</button>
  </div>
</template>

<style scoped>

</style>